import "./App.css";
import "../src/styles/main.scss";
import MainRoutes from "./routes/routes";
import { useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "./redux/auth/authType";
import { logout } from "./utils/auth";
import decode from "jwt-decode";

function App() {
  const { token, iat } = useSelector((state) => (state as any)?.authReducer);

  const dispatch = useDispatch();
  const timer: any = useRef();

  function checkIdleness() {
    var t: any;
    window.onload = timerReset;
    window.onmousemove = timerReset;
    window.onmousedown = timerReset; // catches touchscreen presses as well
    window.ontouchstart = timerReset; // catches touchscreen swipes as well
    window.onclick = timerReset; // catches touchpad clicks as well
    window.onkeydown = timerReset;
    window.addEventListener("scroll", timerReset, true); // improved; see comments

    function writeYourFunction() {
      logout("/");
      Cookies.remove("authToken", { path: "/" });
      Cookies.remove("services", { path: "/" });
      window.location.href = "/";

      dispatch({
        type: LOGOUT,
        payload: {
          token: null,
          type: null,
        },
      });
    }

    function timerReset() {
      clearTimeout(t);
      t = setTimeout(writeYourFunction, 3600000); // time is in milliseconds
    }
  }

  function checkTokenValidity() {
    function checkToken() {
      const now = Date.now();
      const exp = new Date(iat || Date.now()).valueOf();
      // console.log("reeeee", now - exp, 2 * 24 * 60 * 60 * 1000);

      if (iat && now - exp > 1 * 24 * 60 * 60 * 1000) {
        logout("/");
        Cookies.remove("authToken", { path: "/" });
        Cookies.remove("services", { path: "/" });
        window.location.href = "/";
        dispatch({
          type: LOGOUT,
          payload: {
            token: null,
            type: null,
          },
        });
      }
    }

    timer.current = setInterval(checkToken, 1000); // time is in milliseconds
  }

  useEffect(() => {
    if (token) {
      checkIdleness();
      checkTokenValidity();
    }
  }, [token]);

  return <MainRoutes />;
}

export default App;
