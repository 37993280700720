import { Breadcrumb } from "antd";
import { ReactElement } from "react";

const TopHeading = ({
  title,
  total,
}: {
  title: any;
  total: any;
}): ReactElement => {
  return (
    <div className="flex py-3 items-end">
      <div className="w-1/2 h-12">
        <h1 className="text-2xl font-bold mr-4 mb-0">
          {title} List
          <small className="text-xs ml-2 font-light">
            {total} {`${title}(s)`}
          </small>
        </h1>
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="w-1/2 h-12 justify-end text-right">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add New
        </button>
      </div>
    </div>
  );
};

export default TopHeading;
