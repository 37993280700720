import React from "react";
import ChangePassword from "../components/Layouts/ChangePassword";
import MainLayout from "../components/Layouts";

const ChangePasswordPage = () => {
  return (
    <MainLayout>
      <ChangePassword />
    </MainLayout>
  );
};

export default ChangePasswordPage;
